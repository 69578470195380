<template>
  <div class="relative inline-block">
    <component
      :is="component"
      :alt="alt"
      class="transition-opacity"
      :height="height"
      loading="lazy"
      :provider="provider ?? 'cloudinary'"
      :src="formattedUrl"
      :width="width"
      @error="onError"
      @load="onLoad"
    />

    <div v-show="errored" aria-hidden="true" class="absolute inset-0">
      <slot name="fallback" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const NuxtImg = resolveComponent("nuxt-img");

const properties = defineProps<{
  src: string;
  alt: string;
  width?: number;
  height?: number;
  provider?: "cloudinary";
}>();

const {
  public: {
    cloudinary: { baseUrl },
  },
} = useRuntimeConfig();

const formattedUrl = computed(() => properties.src.replace(baseUrl, ""));

const component = computed(() =>
  properties.src.includes(baseUrl) ? NuxtImg : "img",
);

const errored = ref(false);
const loaded = ref(false);

const onError = () => {
  errored.value = true;
  loaded.value = false;
};

const onLoad = () => {
  errored.value = false;
  loaded.value = true;
};
</script>
